import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next";
import Select from "react-select"
import { Button, Col, Label, Row } from "reactstrap"
import { languageOpt } from "../../../../../constants/utils";
import { SAVE_SUCCESS, SELECT_OPTION, UPDATE_SUCCESS } from "../../../../../constants/messages";
import useUser from "../../../../../../hooks/useUser";
import parseObjectToQueryUrl from "../../../../../../util/parseObjectToQueryUrl";
import QuestionHandle from "./QuestionHandle";
import useGetSurveyAnsweredPaginateByUsernameQuery from "../../../../../../hooks/Queries/useGetSurveyAnsweredPaginateByUsernameQuery";
import { useMutation, useQueryClient } from "react-query";
import { createSurveyAnswered, updateSurveyAnswered } from "../../../../../../helpers/catalogues/surveyAnswer";
import useBanner from "../../../../../../hooks/useBanner";
import ButtonsLoader from "../../../../../Loader/ButtonsLoader";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../../common/globalsProp";
import FormSurveySendBySMS from "./FormSurveySendBySMS";
import FormSurveySendByEmail from "./FormSurveySendByEmail";

const FormSurveyAnswered = ({ closeModal, customerId=null, reservationId=null, item=null, customer}) => {
    console.log(customer)
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.operation.surveyAnswer',
    });
    const { t: tMConstant } = useTranslation('translation', {
        keyPrefix: 'constants.language',
    });
    const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
    const user = useUser()
    const banner = useBanner();
    const queryClient = useQueryClient()
    const [surveySelected, setSurveySelected] = useState(item?.survey ? {...item.survey, label: item.survey.title} : null)
    const [lang, setLang] = useState(item?.survey?.lang ?{value: item?.survey?.lang, label: tMConstant(item?.survey?.lang) } : null)
    const [anwserQuestions, setAnwserQuestions] = useState(item?.anwserQuestions ?? [])
    const [query, setQuery] = useState({
            max: 100,
            page: 1,
            lang: '',
            username: user.usuario,
            title: ''
    });
    const queryFilter = useMemo(() => {
        return parseObjectToQueryUrl(query)
    }, [query])
    const configQuery = {
		keepPreviousData: true,
	};

    const { data: surveyOpt } = useGetSurveyAnsweredPaginateByUsernameQuery({
        queryPath: queryFilter,
		configQuery,
        enabled: lang !== null,
        full: false
    })

    const onHandleLinealAnswer = (response, question) => {
        const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
        if(findIndex === -1){
            const obj = {
                answerResponse: response,
                question: {
                    id: question.id
                }
            }
            setAnwserQuestions(prev => [...prev, obj])
        }else{
            const copyAnswer = [...anwserQuestions]
            copyAnswer[findIndex].answerResponse = response
            setAnwserQuestions(copyAnswer)
        }
    }
    const onHandleMultipleAnswer = (response, checked, question) => {
        const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
        if(findIndex === -1){
            const obj = {
                answerResponse: response,
                question: {
                    id: question.id
                }
            }
            setAnwserQuestions(prev => [...prev, obj])
        }else{
            const copyAnswer = [...anwserQuestions]
            const arrAnswer = copyAnswer[findIndex].answerResponse.length > 0 ? copyAnswer[findIndex].answerResponse.split(",") : []
            arrAnswer.push(response)
            if(!checked){
                copyAnswer[findIndex].answerResponse = arrAnswer.filter(it=>it!==response && it!=="").join(",")
            }else{
                copyAnswer[findIndex].answerResponse = arrAnswer.join(",")
            }            
            setAnwserQuestions(copyAnswer)
        }
    }
    const onHandleDateAnswer = (response, question) => {
        const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
        if(findIndex === -1){
            const obj = {
                answerResponse: response ? moment(response).format(DATE_FORMAT) : '',
                question: {
                    id: question.id
                }
            }
            setAnwserQuestions(prev => [...prev, obj])
        }else{
            const copyAnswer = [...anwserQuestions]
            copyAnswer[findIndex].answerResponse = response ? moment(response).format(DATE_FORMAT) : ''
            setAnwserQuestions(copyAnswer)
        }
    }

    //create survey answered
	const { mutate: createItem, isLoading: isCreating } = useMutation({
		mutationFn: createSurveyAnswered,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(SAVE_SUCCESS));
			closeModal();
            queryClient.refetchQueries({queryKey: ['getSurveyAnsweredPaginate']})
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});
    //update survey answered
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateSurveyAnswered,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			closeModal();
            queryClient.refetchQueries({queryKey: ['getSurveyAnsweredPaginate']})
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

    const handleSendSurveyAnswered = () => {
        const data = {
            createdBy: user.usuario,
            //"personResponse": "Alain Nescolarde Pina",
            customer: {
                id: customerId
            },
            reservation: {
                id: reservationId
            },
            survey: {
                id: surveySelected.id
            },
            anwserQuestions: anwserQuestions
        }
        if(item?.id){
            const dataToUpdated = {
                id: item.id,
                body: {
                    ...data
                }
            }
            updateItem(dataToUpdated)
        }else{
            createItem(data)
        }
        
    }

    return (
        <>
            <Row>
                <Col xs='12' md="4">
                    <div className="mb-2">
                        <Label className="form-label mb-0" htmlFor="lang">
							{t('language')}
						</Label>
						<Select
							id="lang"
							className="mb-0"
							value={lang}
							onChange={(value) => {
                                setLang(value)
                                setQuery(prev=> ({
                                    ...prev,
                                    lang: value?.value ?? ''
                                }))
                            }}
							options={languageOpt.map((it) => ({
								...it,
								label: tMConstant(it.label),
							}))}
							placeholder={tMessage(SELECT_OPTION)}
                            isDisabled={item?.readOnly}
						/>
					</div>
                </Col>
                <Col xs='12' md="8">
                    <div className="mb-2">
                        <Label className="form-label mb-0" htmlFor="survey">
							{t('survey')}
						</Label>
						<Select
							id="survey"
							className="mb-0"
							value={surveySelected}
							onChange={(value) => setSurveySelected(value)}
							options={surveyOpt}
							placeholder={tMessage(SELECT_OPTION)}
                            isDisabled={item?.readOnly}
						/>
					</div>
                </Col>
            </Row>
            <FormSurveySendBySMS customer={customer} closeModal={closeModal} surveySelected={surveySelected} />
            <FormSurveySendByEmail customer={customer} closeModal={closeModal} surveySelected={surveySelected} />
            {!surveySelected && <p className="bg-light p-5 text-center">Seleccionar una encuesta</p>}
            {surveySelected &&
                <div className="d-flex flex-column align-items-center mt-3 bg-light p-2" style={{ gap: '12px'}}>
                    {(surveySelected?.questions ?? []).sort((a,b) => a.sorder - b.sorder).map((question) => (
                        <div key={question.id} className="w-100">
                            <QuestionHandle
                                question={question}
                                onHandleLinealAnswer={onHandleLinealAnswer}
                                onHandleMultipleAnswer={onHandleMultipleAnswer}
                                onHandleIndividualAnswer={onHandleLinealAnswer}
                                onHandleTextAnswer={onHandleLinealAnswer}
                                onHandleDateAnswer={onHandleDateAnswer}
                                anwserQuestions={item?.anwserQuestions ?? []}
                            />
                        </div>
                    ))}
                    <div className="d-flex justify-content-center py-4">
                        {(isCreating || isUpdating) && 
                            <ButtonsLoader
                                buttons={[
                                    {
                                        text: t('ok'),
                                        color: 'primary',
                                        loader: true,
                                    },
                                ]}
                            />
                        }
                        {(!isCreating && !isUpdating) && <Button 
                            type="button" 
                            color="primary"
                            onClick={item?.readOnly ? closeModal : handleSendSurveyAnswered}
                        >
                            {t('ok')}
                        </Button>}
                    </div>
                </div>
            }
        </>
    )


}

export default FormSurveyAnswered