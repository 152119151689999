import moment from "moment"

export const parseTime = (time, messure, format) => {
    if(!time) return ''
    const duration = moment.duration(time, messure)
    switch(format){
        case 'minutes':
            return duration.asMinutes().toFixed(0)
        default:
            return duration.asSeconds()
    }
}