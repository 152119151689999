import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import SemiCircularRadial from '../Charts/SemiCircularRadial';
import Widget from '../Charts/Widgets';
import BasicLineCharts from '../Charts/BasicLineCharts';
import Select from 'react-select';
import { SELECT_OPTION } from '../constants/messages';
import { useTranslation } from 'react-i18next';
import ResumeWidgets from '../Charts/ResumeWidgets';
import UnderBuilding from '../Common/UnderBuilding';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { getMainBoardStats } from '../../helpers/dashboard';
import { parseTime } from '../../util/dates';
import { agentTableFilter } from './utils/initialValues';
import Loader from '../Common/Loader';
import DashboardFormFilter from './DashboardFormFilter';
import useDashboardData from '../../hooks/Dashboard/useDashboardData';

const DashboardSupervisor = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.dashboard',
	})
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const [filterType, setFilterType] = useState(null) 

	const { mutate, data, isSuccess, isLoading } = useMutation({
		mutationFn: getMainBoardStats,
	})

	const {dataWidget, barTotalCall, barCallTimes, calls, agents} = useDashboardData(data, isSuccess, t)

	const agentsFiltered = useMemo(() => {
		if(!filterType) return agents
		const result = agents.flatMap(({ userId, calls }) =>
			calls.filter(call => call.concept.toLowerCase() === filterType.value).map(call => ({ userId, calls: [call] }))
		);
		return result
	}, [agents, filterType])

	const handleSearch = useCallback((body) => {
		mutate(body)
	}, [mutate])

	return (
		<>
			<DashboardFormFilter handleSearch={handleSearch} />
			<Row>
				{isLoading && <Loader />}
				{(!isLoading && isSuccess) && <ResumeWidgets crmWidgets={dataWidget} />}
			</Row>
			<Row>
				<Col xs={6} md={3}>
					<Card className="card-animate overflow-hidden">
						<CardBody>
							<SemiCircularRadial
								dataColors='["--vz-primary"]'
								title={'Bono Mensual'}
							/>
							<UnderBuilding />
						</CardBody>
					</Card>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('callsReceived'),
									count:  calls?.received,
									countClass: '',
									iconClass: calls ? 'bx bx-phone fs-1' : '',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('inboundCalls'),
									count: calls?.inbound,
									countClass: 'text-success',
									iconClass: 'bx bx-phone-incoming fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('completedCalls'),
									count: calls?.completed,
									countClass: 'text-success',
									iconClass: 'bx bx-check-circle fs-1',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('outboundCalls'),
									count: calls?.outbound,
									countClass: 'text-danger',
									iconClass: 'bx bx-phone-outgoing fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('timeInCall'),
									count: `${parseTime(calls?.talkTime, 'seconds', 'minutes')} min`,
									countClass: '',
									iconClass: 'bx bx-time-five fs-1',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('avgTalkTime'),
									count: `${parseTime(calls?.avgTalkTime, 'seconds', 'minutes')} min`,
									countClass: '',
									iconClass: 'bx bx-time-five fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>

				<Col xs="12" md="6">
					{(!isLoading && isSuccess) && 
						<Card className="card-animate overflow-hidden">
							<CardHeader>
								<h4 className="card-title mb-0 text-uppercase">{t('callTotal')}</h4>
							</CardHeader>
							<CardBody>
								<div>
									<BasicLineCharts options={barTotalCall.options} series={barTotalCall.series} />
								</div>
							</CardBody>
						</Card>
					}					
				</Col>
				<Col xs="12" md="6">
					{(!isLoading && isSuccess) && 
						<Card className="card-animate overflow-hidden">
							<CardHeader>
								<h4 className="card-title mb-0">
									{t('timeInCall')} (min)
								</h4>
							</CardHeader>
							<CardBody>
								<div>
									<BasicLineCharts options={barCallTimes.options} series={barCallTimes.series} />
								</div>
							</CardBody>
						</Card>
					}					
				</Col>
			</Row>
			<Row>
				<Col xs="12">
					<Card className="card-animate overflow-hidden">
						<CardHeader className='align-items-center d-flex'>
							<h4 className="card-title mb-0 flex-grow-1">{t('agentStats')}</h4>
							<div>
								<Select
									id="option"
									className="mb-0"
									value={filterType}
									onChange={setFilterType}
									options={agentTableFilter}
									isClearable
									placeholder={tMessage(SELECT_OPTION)}
								/>
							</div>
						</CardHeader>
						<CardBody>
							{
								agentsFiltered.map(({userId, calls}) => (
									<Table key={userId} className='m-0'>
										<thead class="table-light">
											<tr class="text-muted">
												<th width={'15%'} className='fw-bold text-primary'>{userId}</th>
												<th width="8%">{t('callsReceived')}</th>
												<th width="10%">{t('completedCalls')}</th>
												<th width="8%">{t('transferred')}</th>
												<th width="12%">{t('percentCalls')}</th>
												<th width="8%">{t('talkTime')}</th>
												<th width="8%">{t('avgTalkTime')}</th>
												<th width="8%">{t('percentTalkTime')}</th>
												<th width="8%">{t('maxWaitTime')}</th>	
												<th width="8%">{t('waitTime')}</th>
												<th width="12%">{t('avgWaitTime')}</th>											
												
											</tr>
										</thead>
										<tbody>
											{
												calls.map((call) => (
													<tr>
														<td>{call.concept}</td>
														<td>{call.received}</td>
														<td>{call.completed}</td>
														<td>{call.transferred}</td>
														<td>{call.percentCalls.toFixed(2)}%</td>
														<td>{parseTime(call.talkTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.avgTalkTime, 'seconds', 'minutes')} min</td>
														<td>{call.percentTalkTime.toFixed(2)}%</td>
														<td>{parseTime(call.maxWaitTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.waitTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.avgWaitTime, 'seconds', 'minutes')} min</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								))
							}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DashboardSupervisor;
