import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGetSurveyById from "../../hooks/Queries/useGetSurveyById";
import Loader from "../../Components/Common/Loader";
import QuestionHandle from "../../Components/Operation/Lead/Tab/Survey/Preview/QuestionHandle";
import ButtonsLoader from "../../Components/Loader/ButtonsLoader";
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useMutation } from "react-query";
import { createSurveyAnswered } from "../../helpers/catalogues/surveyAnswer";
import moment from "moment";
import { DATE_FORMAT } from "../../common/globalsProp";

const SurveyResponse = () => {
    const {customerId, userAgent, reservationId, surveyId} = useParams();
    const [anwserQuestions, setAnwserQuestions] = useState([])
    const [error, setError] = useState(false)

    const { t } = useTranslation('translation', {
        keyPrefix: 'components.operation.surveyAnswer',
    });
    const [view, setView] = useState('survey')

    const {data: surveySelected, isFetching} = useGetSurveyById(surveyId)

    //create survey answered
	const { mutate: createItem, isLoading: isCreating } = useMutation({
		mutationFn: createSurveyAnswered,
		onSuccess: () => {
			//se hizo correctamente
            setView('done')
		},
		onError: () => {
            setError(true)
		},
	});

    const onHandleLinealAnswer = (response, question) => {
            const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
            if(findIndex === -1){
                const obj = {
                    answerResponse: response,
                    question: {
                        id: question.id
                    }
                }
                setAnwserQuestions(prev => [...prev, obj])
            }else{
                const copyAnswer = [...anwserQuestions]
                copyAnswer[findIndex].answerResponse = response
                setAnwserQuestions(copyAnswer)
            }
    }
    const onHandleMultipleAnswer = (response, checked, question) => {
        const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
        if(findIndex === -1){
            const obj = {
                answerResponse: response,
                question: {
                    id: question.id
                }
            }
            setAnwserQuestions(prev => [...prev, obj])
        }else{
            const copyAnswer = [...anwserQuestions]
            const arrAnswer = copyAnswer[findIndex].answerResponse.length > 0 ? copyAnswer[findIndex].answerResponse.split(",") : []
            arrAnswer.push(response)
            if(!checked){
                copyAnswer[findIndex].answerResponse = arrAnswer.filter(it=>it!==response && it!=="").join(",")
            }else{
                copyAnswer[findIndex].answerResponse = arrAnswer.join(",")
            }            
            setAnwserQuestions(copyAnswer)
        }
    }
    const onHandleDateAnswer = (response, question) => {
        const findIndex = anwserQuestions.findIndex(it=> it.question.id === question.id)
        if(findIndex === -1){
            const obj = {
                answerResponse: response ? moment(response).format(DATE_FORMAT) : '',
                question: {
                    id: question.id
                }
            }
            setAnwserQuestions(prev => [...prev, obj])
        }else{
            const copyAnswer = [...anwserQuestions]
            copyAnswer[findIndex].answerResponse = response ? moment(response).format(DATE_FORMAT) : ''
            setAnwserQuestions(copyAnswer)
        }
    }

    const handleSendSurveyAnswered = () => {
        const data = {
            answerByCustomer: true,
            createdBy: userAgent,
            //"personResponse": "Alain Nescolarde Pina",
            customer: {
                id: customerId
            },
            reservation: reservationId ? {
                id: reservationId
            } : null,
            survey: {
                id: surveySelected.id
            },
            anwserQuestions: anwserQuestions
        }
        createItem(data)
        
    }

    if (isFetching) return <Loader />;

    if(view === 'done'){
        return (
            <Row className="mt-5">
                <Col xs="12" md={{offset: 4, size: 4}}>
                    <h1 className="text-center mb-2">Gracias por responder nuestra encuesta</h1>
                </Col>
            </Row>
        )
    }

    return (
        <Row className="mt-5">
            <Col xs="12" md={{offset: 2, size: 8}}>
                {error && <Alert color="danger" className="text-center">No se pudo procesar la información, intente nuevamente</Alert>}
                <h1 className="text-center mb-2">{t('surveySatisfaction')}</h1>
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                            {surveySelected.title}
                        </h4>
                    </CardHeader>
                    <CardBody>
                    {surveySelected &&
                    <div className="d-flex flex-column align-items-center mt-3 bg-light p-2" style={{ gap: '12px'}}>
                        {(surveySelected?.questions ?? []).sort((a,b) => a.sorder - b.sorder).map((question) => (
                            <div key={question.id} className="w-100">
                                <QuestionHandle
                                    question={question}
                                    onHandleLinealAnswer={onHandleLinealAnswer}
                                    onHandleMultipleAnswer={onHandleMultipleAnswer}
                                    onHandleIndividualAnswer={onHandleLinealAnswer}
                                    onHandleTextAnswer={onHandleLinealAnswer}
                                    onHandleDateAnswer={onHandleDateAnswer}
                                    anwserQuestions={[]}
                                />
                            </div>
                        ))}
                        <div className="d-flex justify-content-center py-4">
                            {(isCreating) && 
                                <ButtonsLoader
                                    buttons={[
                                        {
                                            text: t('ok'),
                                            color: 'primary',
                                            loader: true,
                                        },
                                    ]}
                                />
                            }
                            {(!isCreating) && <Button 
                                type="button" 
                                color="primary"
                                onClick={handleSendSurveyAnswered}
                            >
                                {t('ok')}
                            </Button>}
                        </div>
                    </div>
                }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default SurveyResponse