import { useQuery } from 'react-query';
import { getSurveyById } from '../../helpers/catalogues/survey';

const useGetSurveyById = (id) => {
	const query = useQuery(['getSurveyById', id], () => getSurveyById(id), {
		enabled: !!id,
		select: (response) => response.data,
	});
	return query;
};

export default useGetSurveyById;
