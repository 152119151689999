const UnderBuilding = () => {
	return (
		<div>
			<h1
				className="position-absolute bottom-0 end-0"
				style={{
					color: 'red',
					fontSize: '20px',
				}}
			>
				<i className="ri-settings-3-line" /> C/S
			</h1>
		</div>
	);
};

export default UnderBuilding;
