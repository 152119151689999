import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TablePaginate from '../../Common/TablePaginate';

export const getItems = (items, showBy) => {
	if (showBy === 'departments') {
		const result = items.departments.map((it) => ({
			name: it.department,
			total: it.total,
			list: it.list,
		}));
		return result;
	} else if (showBy === 'callcenter') {
		const result = items.callCenters.map((it) => ({
			name: it.callCenter,
			total: it.total,
			list: it.list,
		}));
		return result;
	} else if (showBy === 'source') {
		const result = items.sources.map((it) => ({
			name: it.source ?? 'Otro',
			total: it.total,
			list: it.list,
		}));
		return result;
	} else {
		const result = items.hotels.map((it) => ({
			name: it.hotel,
			total: it.total,
			list: it.list,
		}));
		return result;
	}
};

const TableSource = ({ items, showBy }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.tableSource',
	});
	const data = useMemo(() => {
		if (!items) return [];
		return getItems(items, showBy);
	}, [showBy, items]);

	const columns = useMemo(
		() => [
			{
				Header: t('department'),
				accessor: 'DEPARTAMENTO',
				filterable: false,
				style: {
					width: '15%',
				},
			},
			{
				Header: 'ID Booking',
				accessor: 'BOOKING',
				filterable: false,
				style: {
					width: '7%',
				},
			},
			{
				Header: t('reservationId'),
				accessor: 'IDRESERVACION',
				filterable: false,
				style: {
					width: '8%',
				},
			},
			{
				Header: t('name'),
				accessor: 'NOMBRECLIENTE',
				filterable: false,
				style: {
					width: '10%',
				},
			},
			{
				Header: t('status'),
				accessor: 'STATUS',
				filterable: false,
				style: {
					width: '10%',
				},
			},
			{
				Header: t('agent'),
				accessor: 'IDUSER',
				filterable: false,
				style: {
					width: '10%',
				},
			},
			{
				Header: 'Call center',
				accessor: 'NOMBRECC',
				filterable: false,
				style: {
					width: '15%',
				},
			},
			{
				Header: t('hotel'),
				accessor: 'HOTEL',
				filterable: false,
				style: {
					width: '13%',
				},
			},
			{
				Header: 'Source',
				accessor: 'SOURCE',
				filterable: false,
				style: {
					width: '7%',
				},
			},
			{
				Header: t('country'),
				accessor: 'PAIS',
				filterable: false,
				style: {
					width: '5%',
				},
			},
		],
		[t]
	);

	return (
		<table className="table table-centered align-middle table-wrap mb-0 fs-7">
			<tbody>
				{data.map((it, idx) => (
					<Fragment key={`table-source-${idx}`}>
						<tr>
							<th colSpan={8} className="table-light">
								{it.name}
							</th>
							<th className="table-light text-end">
								{`${t('reservationTotal')}: ${it.total}`}
							</th>
						</tr>
						<tr>
							<td colSpan={9} className="px-0">
								<TablePaginate
									columns={columns}
									data={it.list}
									tableClass="align-middle table-wrap"
									theadClass=""
									className="custom-header-css"
									divClass="table-responsive mb-3"
									initialState={{
										pageSize: 10,
										pageIndex: 0,
									}}
								/>
							</td>
						</tr>
					</Fragment>
				))}
			</tbody>
		</table>
	);
};

export default TableSource;
