import React from 'react';
import CountUp from 'react-countup';
import UnderBuilding from '../Common/UnderBuilding';

const ResumeWidgets = ({ crmWidgets, underBuilding = false }) => {
	return (
		<React.Fragment>
			<div className="col-xl-12">
				<div className="card crm-widget">
					<div className="card-body p-0">
						<div className="d-flex flex-column flex-md-row justify-content-between">
							{crmWidgets.map((widget, index) => (
								<div className="col" key={index}>
									{underBuilding && <UnderBuilding />}
									<div className="py-4 px-3">
										<h5 className="text-muted text-uppercase fs-13">
											{widget.label}
											{widget.badge && <i
												className={
													widget.badge +
													' fs-18 float-end align-middle'
												}
											/>}
										</h5>
										<div className="d-flex align-items-center">
											<div className="flex-shrink-0">
												<i
													className={
														widget.icon +
														' display-6'
													}
												></i>
											</div>
											<div className="flex-grow-1 ms-3">
												<h2 className="mb-0">
													<span
														className="counter-value"
														data-target="197"
													>
														<CountUp
															start={0}
															prefix={
																widget.prefix
															}
															suffix={
																widget.suffix
															}
															separator={
																widget.separator
															}
															end={widget.counter}
															decimals={
																widget.decimals
															}
															duration={4}
														/>
													</span>
												</h2>
											</div>
										</div>
										{widget.subLabel && (
											<small
												className={`d-flex align-items-center text-muted ${
													widget.onClick
														? 'cursor-pointer'
														: ''
												}`}
												onClick={widget.onClick}
											>
												<i
													className={
														widget.subLabelIcon +
														' text-muted me-1'
													}
												/>
												{widget.subLabel}
											</small>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ResumeWidgets;
