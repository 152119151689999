import { useEffect, useState } from "react";
import DatePicker from "../../../../../Common/DatePicker";
import moment from "moment";
import { DATE_FORMAT } from "../../../../../../common/globalsProp";

const QuestionDate = ({ question, onHandleDateAnswer, anwserQuestions }) => {
    const [date, setDate] = useState(null);
      useEffect(() => {
          const findIndex = anwserQuestions.findIndex(it=>it.question.id === question.id)
          if(findIndex !== -1){
            if(anwserQuestions[findIndex].answerResponse){
                setDate(moment(anwserQuestions[findIndex].answerResponse, DATE_FORMAT).toDate())
            }
          }
      }, [anwserQuestions, question.id])
        return (
          <div className="d-flex align-items-center" style={{ gap: '4px'}}>
            <h6 className="mb-0">{question.title}</h6>
            <div className="flex-1">
                <DatePicker
                    date={date}
                    onChangeDate={(value) => {
                        setDate(value[0]);
                        onHandleDateAnswer(value[0], question)
                    }}
                />
            </div>            
          </div>
        );
}

export default QuestionDate