export const existsRole = (roles, rolesToCheck) => {
	if (!rolesToCheck) return true;
	if (!roles) return true;
	const hasAccess = roles.some((r) => rolesToCheck.includes(r));
	// roles.every((r) => {
	// 	if (rolesToCheck.includes(r)) {
	// 		hasAccess = true;			
	// 	}
	// });
	return hasAccess;
};
