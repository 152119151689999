import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Input, Label, Row } from "reactstrap";
import useBanner from "../../../../../../hooks/useBanner";
import { sendEmailSurvey } from "../../../../../../helpers/catalogues/survey";
import { useMutation } from "react-query";
import { SMS_SUCCESS } from "../../../../../constants/messages";
import ButtonsLoader from "../../../../../Loader/ButtonsLoader";
import useUser from "../../../../../../hooks/useUser";

const FormSurveySendByEmail = ({ customer, closeModal, surveySelected }) => {
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [email, setEmail] = useState(customer?.email ?? '');
    const banner = useBanner();
    const user = useUser()
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.operation.surveyAnswer',
    });
    const { t: tMessage } = useTranslation('translation', {
        keyPrefix: 'messages',
    });

    useEffect(() => {
        if(customer?.email){
            setEmail(customer.email)
        }
    }, [customer?.email])

    //send sms
	const { mutate: sendEmail, isLoading: isSendingEmail } = useMutation(
		sendEmailSurvey,
		{
			onSuccess: () => {
				banner.simpleSuccess(tMessage(SMS_SUCCESS));
				closeModal();
			},
			onError: (error) => {
				banner.simpleError(error);
			},
		}
	);

    const handleSend = () => {
        if(!surveySelected || !email){
            banner.simpleWarning(t('validateSendEmail'))
        }else{
            const data = {
                customer: customer.id,
                survey: surveySelected.id,
                user: user.usuario,
                email: email
            }
            sendEmail(data)
        }
    }
    return (
        <>
            <Row>
                <Col xs="12" lg="12">
                    <div className="mb-2">
                        <div className="form-check me-4">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="sendEmail"
                                name="sendEmail"
                                checked={showSendEmail}
                                onChange={(evt) =>
                                    setShowSendEmail(
                                        evt.target.checked
                                    )
                                }
                            />
                            <Label
                                className="form-check-label"
                                htmlFor="sendEmail"
                            >
                                {t('sendEmail')}
                            </Label>
                        </div>
                    </div>
                </Col>
            </Row>
            {
                showSendEmail && 
                <Row>
                    <Col xs="8" lg="9">
                        <div className="mb-2">
                            <Label className="form-label mb-0" htmlFor="nota">
                                {t('to')}
                            </Label>
                            <Input
                                className="form-control"
                                type="text"
                                id="booking"
                                value={email}
                                onChange={(e) =>
                                    setEmail(e.target.value)
                                }
                            />                            
                        </div>
                    </Col>
                    <Col xs="4" lg="3">
                        <div className="d-flex flex-column mb-2">
                            <Label className="form-label mb-0 opacity-0" htmlFor="nota">
                                {t('number')}
                            </Label>
                            {isSendingEmail && <ButtonsLoader buttons={[{ text: t('send'), color: 'success', loader: true }]} />}
                            {!isSendingEmail &&  <Button type="button" color="success" onClick={handleSend}>
                                                    <i className="ri ri-mail-send-line align-bottom" /> {t('send')}
                                                </Button>
                            }                           
                        </div>      
                    </Col>
                </Row>
            }
        </>
    )
}

export default FormSurveySendByEmail