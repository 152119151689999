export const crmWidgets = [
    {
		id: 1,
		label: 'Revenue',
		badge: null,
		icon: 'ri-money-dollar-circle-line',
		counter: null,
		decimals: 2,
		suffix: '',
		prefix: '$',
	},
    {
		id: 2,
		label: 'LLamadas',
		badge: null,
		icon: 'ri-phone-line',
		counter: null,
		decimals: 0,
		suffix: '',
		prefix: '',
	},
	{
		id: 3,
		label: 'Tiempo en llamada',
		badge: null,
		icon: ' ri-timer-line',
		counter: null,
		decimals: 0,
		suffix: 'min',
		prefix: '',
	},
	{
		id: 4,
		label: 'Reservaciones confirmadas',
		badge: null,
		icon: 'ri-trophy-line',
		counter: null,
		decimals: 0,
		prefix: '',
		separator: ',',
		suffix: '',
	},
	{
		id: 5,
		label: 'pre-confirmadas',
		badge: null,
		icon: 'ri-service-line',
		counter: null,
		decimals: 0,
		separator: ',',
		suffix: '',
		prefix: '',
	},
];

export const agentTableFilter = [
	{ value: 'inbound', label: 'Inbound'},
	{ value: 'outbound', label: 'Outbound'}
]