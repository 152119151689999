import getChartColorsArray from "../../Common/ChartsDynamicColor";



export const confChartReservations = (categories, color) => {
    const linechartBasicColors = getChartColorsArray(color);
    const options = {
		chart: {
			height: 350,
			type: 'line',
			zoom: {
				enabled: false,
			},
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: 4,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'straight',
		},
		colors: linechartBasicColors,
		title: {
			text: '',
			align: 'left',
			style: {
				fontWeight: 500,
			},
		},

		xaxis: {
			categories: [...categories],
		},
	};

    return options
}

export const agentStatsDashboard = (agentStats) => {
    const result = Object.entries(agentStats).map(([userId, records]) => {
        const completed = records
          .filter(record => record.agent.includes('Agent'))
          .reduce((sum, record) => sum + record.completed, 0);
        
        return { userId, completed };
    });

    return result
}

export const callsUserInbOutDashboard = (agentStats) => {
	const result = Object.entries(agentStats).map(([userId, records]) => {
		const totals = records.reduce(
		  (acc, record) => {
			if (record.agent.includes('Agent')) {
			  acc.inbound += record.completed;
			} else if (record.agent.includes('Sip')) {
			  acc.outbound += record.completed;
			}
			return acc;
		  },
		  { userId, inbound: 0, outbound: 0 }
		);
	  
		return totals;
	});

	return result
}

export const totalCallInbOut = (agentStats) => {
	const agentArr = callsUserInbOutDashboard(agentStats)

	const result = agentArr.reduce((acc, curr) => {

		acc.inbound += curr.inbound
		acc.outbound += curr.outbound
		return acc

	}, {
		inbound: 0,
		outbound: 0
	})

	return result
}