import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Button, Col, Row } from "reactstrap";
import useUser from "../../hooks/useUser";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAgentsBySupervisor } from "../../helpers/customer";
import { SELECT_OPTION } from "../constants/messages";
import DatePicker from "../Common/DatePicker";
import moment from "moment";
import { DATE_FORMAT_SEND_REQUEST } from "../../common/globalsProp";

const DashboardFormFilter = ({ handleSearch }) => {
    const { t: tMessage } = useTranslation('translation', {
        keyPrefix: 'messages',
    });
    const { t } = useTranslation('translation', {
		keyPrefix: 'pages.dashboard',
	})
    const user = useUser()
	const [date, setDate] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);

    //agents by super/manager
	const { data: agentsOpt } = useQuery(
		['getAgentsBySupervisor', user.usuario],
		() => getAgentsBySupervisor(user.usuario),
		{
			enabled: user !== null,
			select: (result) =>
				result.data.list.map((it) => ({
					value: it.id,
					label: it.id,
				})) ?? [],
		}
	);

    useEffect(() => {
        const body = {
            "dateRange": {
                "start": "",
                "end": ""
            },
            "userName": user.usuario,
            "salesAgents": []
        }
        const today = moment().format(DATE_FORMAT_SEND_REQUEST)
        body.dateRange.start = today
        body.dateRange.end = today
        handleSearch(body)
    }, [handleSearch, user])

    const handleClick = () => {
        const body = {
            "dateRange": {
                "start": "",
                "end": ""
            },
            "userName": user.usuario,
            "salesAgents": selectedAgents.map(it=>it.value)
        }
        if(date.length === 0){
            const today = moment().format(DATE_FORMAT_SEND_REQUEST)
            body.dateRange.start = today
            body.dateRange.end = today
            handleSearch(body)
        }else if(date.length === 2){
            const dateStart = moment(date[0]).format(DATE_FORMAT_SEND_REQUEST)
            const dateEnd = moment(date[1]).format(DATE_FORMAT_SEND_REQUEST)
            body.dateRange.start = dateStart
            body.dateRange.end = dateEnd
            handleSearch(body)
        }
    }

    return (
        <Row className="justify-content-end mb-3" style={{ zIndex: 2 }}>
            <Col>
                <div className="d-flex align-items-center justify-content-end">
                    <div className="me-2 min-w-190">
                        <Select
                            value={selectedAgents}
                            onChange={values => setSelectedAgents(values ?? [])}
                            options={agentsOpt}
                            placeholder={tMessage(SELECT_OPTION)}
                            className="shadow-sm"
                            isMulti
                        />
                    </div>
                    <div className="me-2">
                        <div className="input-group">
                            <DatePicker
                                id="rangeDate"
                                className="form-control border-0 dash-filter-picker shadow-sm"
                                options={{
                                    mode: 'range',
                                }}
                                date={date}
                                onChangeDate={(e) => {
                                    setDate(e)
                                }}
                                placeholder={t('selectDateRange')}
                                onClose={(selectedDates) => {
                                    if (selectedDates.length === 0) setDate([]);
                                }}
                            />
                            <div className="input-group-text bg-primary-subtle shadow-sm border-light text-body-secondary">
                                <i className="ri-calendar-2-line" />
                            </div>
                        </div>
                    </div>				
                    <div>
                        <Button onClick={handleClick} color='primary'>
                            <i className="mdi mdi-magnify"></i>{' '}
                            {t('apply')}
                        </Button>
                    </div>		
                </div>
            </Col>
        </Row>
    )
}

export default DashboardFormFilter