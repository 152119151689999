import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import SemiCircularRadial from '../Charts/SemiCircularRadial';
import Widget from '../Charts/Widgets';
import BasicLineCharts from '../Charts/BasicLineCharts';
import DatePicker from '../Common/DatePicker';
import Select from 'react-select';
import { SELECT_OPTION } from '../constants/messages';
import { useTranslation } from 'react-i18next';
import UnderBuilding from '../Common/UnderBuilding';
import useUser from '../../hooks/useUser';
import { parseTime } from '../../util/dates';
import { agentTableFilter, crmWidgets } from './utils/initialValues';
import ResumeWidgets from '../Charts/ResumeWidgets';
import { Loader } from 'feather-icons-react/build/IconComponents';
import { useEffect, useMemo, useState } from 'react';
import { agentStatsDashboard, confChartReservations, totalCallInbOut } from './utils/confChart';
import moment from 'moment';
import { DATE_FORMAT_SEND_REQUEST } from '../../common/globalsProp';
import { getMainBoardStats } from '../../helpers/dashboard';
import { useMutation } from 'react-query';


const DashboardAgent = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.dashboard',
	})
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const user = useUser()
	const [date, setDate] = useState([]);
	const [filterType, setFilterType] = useState(null) 

	const { mutate, data, isSuccess, isLoading } = useMutation({
		mutationFn: getMainBoardStats,
	})
	useEffect(() => {
		const body = {
			"dateRange": {
				"start": "2025-03-01",
				"end": "2025-04-04"
			},
			"userName": user.usuario,
			"agents": [user.usuario]
		}
		if(date.length === 0){
			const today = moment().format(DATE_FORMAT_SEND_REQUEST)
			body.dateRange.start = today
			body.dateRange.end = today
			mutate(body)
		}else if(date.length === 2){
			const dateStart = moment(date[0]).format(DATE_FORMAT_SEND_REQUEST)
			const dateEnd = moment(date[1]).format(DATE_FORMAT_SEND_REQUEST)
			body.dateRange.start = dateStart
			body.dateRange.end = dateEnd
			mutate(body)
		}
	}, [mutate, date, user])

	const {dataWidget, barTotalCall, barCallTimes, calls, agents} = useMemo(() => {
		if(isSuccess){
			//widget
			const { summary, agentStats, talkTime } = data
			const dataWidget = [
				{
					id: 1,
					label: 'Revenue',
					badge: null,
					icon: 'ri-money-dollar-circle-line text-muted',
					counter: null,
					decimals: 2,
					suffix: '',
					prefix: '$',
				},
				{
					id: 4,
					label: t('confirmedReservations'),
					badge: null,
					icon: 'ri-trophy-line text-muted',
					counter: null,
					decimals: 0,
					prefix: '',
					separator: ',',
					suffix: '',
				},
				{
					id: 2,
					label: t('calls'),
					badge: null,
					icon: 'ri-phone-line text-muted',
					counter: summary.completed,
					decimals: 0,
					suffix: '',
					prefix: '',
				},
				{
					id: 3,
					label: t('timeInCall'),
					badge: null,
					icon: ' ri-timer-line text-muted',
					counter: parseTime(summary.talkTime, 'seconds', 'minutes'),
					decimals: 0,
					suffix: 'min',
					prefix: '',
				},
				{
					id: 5,
					label: t('waitTime'),
					badge: null,
					icon: 'ri-timer-line text-danger',
					counter: parseTime(summary.waitTime, 'seconds', 'minutes'),
					decimals: 0,
					suffix: 'min',
					prefix: '',
				},
			]

			//bar reservas
			const agentStatsFormat = agentStatsDashboard(agentStats)
			const barTotalCall = {
				options: confChartReservations(agentStatsFormat.map(it => it.userId), '["--vz-primary"]'),
				series: [{
					name: t('callTotal'),
					data: [...agentStatsFormat.map(it => it.completed)]
				}]
			}
			//bar tiempo en llamada
			const barCallTimes = {
				options: confChartReservations(talkTime.map(it => moment.utc(it.date).local().format("D")), '["--vz-warning"]'),
				series: [{
					name: t('timeInCall'),
					data: [...talkTime.map(it => parseTime(it.talkTime, 'seconds', 'minutes'))]
				}]
			}

			//stats calls
			const { inbound, outbound } = totalCallInbOut(agentStats)
			const calls = {
				received: summary.received,
				completed: summary.completed,
				talkTime: summary.talkTime,
				avgTalkTime: summary.avgTalkTime,				
				inbound: inbound,
				outbound:outbound
			}

			//table agents
			const agents = Object.entries(agentStats).map(([userId, calls]) => ({userId, calls}))


			return {
				dataWidget: dataWidget,
				barTotalCall: barTotalCall,
				barCallTimes: barCallTimes,
				calls: calls,
				agents: agents
			}
		}else{
			return {
				dataWidget: crmWidgets,
				barTotalCall: {
					options: null,
					series: null
				},
				barCallTimes: {
					options: null,
					series: null
				},
				calls: null,
				agents: []
			}
		}
	}, [data, isSuccess, t])
		
	const agentsFiltered = useMemo(() => {
		if(!filterType) return agents
		const result = agents.flatMap(({ userId, calls }) =>
			calls.filter(call => call.concept.toLowerCase() === filterType.value).map(call => ({ userId, calls: [call] }))
		);
		return result
	}, [agents, filterType])

	return (
		<>
			<Row className="justify-content-end mb-3" style={{ zIndex: 2 }}>
				<Col>
					<div className="d-flex align-items-center justify-content-end">
						<div className="me-2">
							<div className="input-group">
								<DatePicker
									id="rangeDate"
									className="form-control border-0 dash-filter-picker shadow-sm"
									options={{
										mode: 'range',
									}}
									date={date}
									onChangeDate={(e) => {
										setDate(e)
									}}
									placeholder={t('selectDateRange')}
									onClose={(selectedDates) => {
										if (selectedDates.length === 0) setDate([]);
									}}
								/>
								<div className="input-group-text bg-primary border-primary text-white">
									<i className="ri-calendar-2-line"></i>
								</div>
							</div>
						</div>						
					</div>
				</Col>
			</Row>
			<Row>
				{isLoading && <Loader />}
				{(!isLoading && isSuccess) && <ResumeWidgets crmWidgets={dataWidget} />}
			</Row>
			<Row>
				<Col xs={6} md={3}>
					<Card className="card-animate overflow-hidden">
						<CardBody>
							<SemiCircularRadial
								dataColors='["--vz-primary"]'
								title={'Bono Mensual'}
							/>
							<UnderBuilding />
						</CardBody>
					</Card>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('callsReceived'),
									count:  calls?.received,
									countClass: '',
									iconClass: calls ? 'bx bx-phone fs-1' : '',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('inboundCalls'),
									count: calls?.inbound,
									countClass: 'text-success',
									iconClass: 'bx bx-phone-incoming fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('completedCalls'),
									count: calls?.completed,
									countClass: 'text-success',
									iconClass: 'bx bx-check-circle fs-1',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('outboundCalls'),
									count: calls?.outbound,
									countClass: 'text-danger',
									iconClass: 'bx bx-phone-outgoing fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>
				<Col xs={6} md={3}>
					<div className="d-flex flex-column">
						<div>
							<Widget
								widget={{
									label: t('timeInCall'),
									count: `${parseTime(calls?.talkTime, 'seconds', 'minutes')} min`,
									countClass: '',
									iconClass: 'bx bx-time-five fs-1',
									loader: isLoading
								}}
							/>
						</div>
						<div>
							<Widget
								widget={{
									label: t('avgTalkTime'),
									count: `${parseTime(calls?.avgTalkTime, 'seconds', 'minutes')} min`,
									countClass: '',
									iconClass: 'bx bx-time-five fs-1',
									loader: isLoading
								}}
							/>
						</div>
					</div>
				</Col>

				<Col xs="12" md="6">
					{(!isLoading && isSuccess) && 
						<Card className="card-animate overflow-hidden">
							<CardHeader>
								<h4 className="card-title mb-0 text-uppercase">{t('callTotal')}</h4>
							</CardHeader>
							<CardBody>
								<div>
									<BasicLineCharts options={barTotalCall.options} series={barTotalCall.series} />
								</div>
							</CardBody>
						</Card>
					}					
				</Col>
				<Col xs="12" md="6">
					{(!isLoading && isSuccess) && 
						<Card className="card-animate overflow-hidden">
							<CardHeader>
								<h4 className="card-title mb-0">
									{t('timeInCall')} (min)
								</h4>
							</CardHeader>
							<CardBody>
								<div>
									<BasicLineCharts options={barCallTimes.options} series={barCallTimes.series} />
								</div>
							</CardBody>
						</Card>
					}					
				</Col>
			</Row>
			<Row>
				<Col xs="12">
					<Card className="card-animate overflow-hidden">
						<CardHeader className='align-items-center d-flex'>
							<h4 className="card-title mb-0 flex-grow-1">{t('agentStats')}</h4>
							<div>
								<Select
									id="option"
									className="mb-0"
									value={filterType}
									onChange={setFilterType}
									options={agentTableFilter}
									isClearable
									placeholder={tMessage(SELECT_OPTION)}
								/>
							</div>
						</CardHeader>
						<CardBody>
							{
								agentsFiltered.map(({userId, calls}) => (
									<Table key={userId} className='m-0'>
										<thead class="table-light">
											<tr class="text-muted">
												<th width={'15%'} className='fw-bold text-primary'>{userId}</th>
												<th width="8%">{t('callsReceived')}</th>
												<th width="10%">{t('completedCalls')}</th>
												<th width="8%">{t('transferred')}</th>
												<th width="12%">{t('percentCalls')}</th>
												<th width="8%">{t('talkTime')}</th>
												<th width="8%">{t('avgTalkTime')}</th>
												<th width="8%">{t('percentTalkTime')}</th>
												<th width="8%">{t('maxWaitTime')}</th>	
												<th width="8%">{t('waitTime')}</th>
												<th width="12%">{t('avgWaitTime')}</th>											
												
											</tr>
										</thead>
										<tbody>
											{
												calls.map((call) => (
													<tr>
														<td>{call.concept}</td>
														<td>{call.received}</td>
														<td>{call.completed}</td>
														<td>{call.transferred}</td>
														<td>{call.percentCalls.toFixed(2)}%</td>
														<td>{parseTime(call.talkTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.avgTalkTime, 'seconds', 'minutes')} min</td>
														<td>{call.percentTalkTime.toFixed(2)}%</td>
														<td>{parseTime(call.maxWaitTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.waitTime, 'seconds', 'minutes')} min</td>
														<td>{parseTime(call.avgWaitTime, 'seconds', 'minutes')} min</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								))
							}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DashboardAgent;
