import { useEffect, useState } from "react";
import { agentStatsDashboard, confChartReservations, totalCallInbOut } from "../../Components/Dashboard/utils/confChart";
import moment from "moment";
import { parseTime } from "../../util/dates";
import { crmWidgets } from "../../Components/Dashboard/utils/initialValues";

const useDashboardData = (data, isSuccess, t) => {
    const [dashboardData, setDashboardData] = useState({
      dataWidget: [],
      barTotalCall: { options: null, series: null },
      barCallTimes: { options: null, series: null },
      calls: null,
      agents: []
    });
  
    useEffect(() => {
      if (isSuccess && data) {
        const { summary, agentStats, talkTime } = data;
  
        // widget
        const dataWidget = [
          { id: 1, label: 'Revenue', badge: null, icon: 'ri-money-dollar-circle-line text-muted', counter: summary.totalRevenue, decimals: 2, suffix: '', prefix: '$' },
          { id: 4, label: t('confirmedReservations'), badge: null, icon: 'ri-hotel-line text-muted', counter: summary.totalConfirmedReservation, decimals: 0, prefix: '', separator: ',', suffix: '' },
          { id: 2, label: t('calls'), badge: null, icon: 'ri-phone-line text-muted', counter: summary.completed, decimals: 0, suffix: '', prefix: '' },
          { id: 3, label: t('timeInCall'), badge: null, icon: ' ri-timer-line text-muted', counter: parseTime(summary.talkTime, 'seconds', 'minutes'), decimals: 0, suffix: 'min', prefix: '' },
          { id: 5, label: t('waitTime'), badge: null, icon: 'ri-timer-line text-danger', counter: parseTime(summary.waitTime, 'seconds', 'minutes'), decimals: 0, suffix: 'min', prefix: '' }
        ];
  
        // bar reservas
        const agentStatsFormat = agentStatsDashboard(agentStats);
        const barTotalCall = {
          options: confChartReservations(agentStatsFormat.map(it => it.userId), '["--vz-primary"]'),
          series: [{ name: t('callTotal'), data: agentStatsFormat.map(it => it.completed) }]
        };
  
        // bar tiempo en llamada
        const barCallTimes = {
          options: confChartReservations(talkTime.map(it => moment.utc(it.date).local().format("D")), '["--vz-warning"]'),
          series: [{ name: t('timeInCall'), data: talkTime.map(it => parseTime(it.talkTime, 'seconds', 'minutes')) }]
        };
  
        // stats calls
        const { inbound, outbound } = totalCallInbOut(agentStats);
        const calls = {
          received: summary.received,
          completed: summary.completed,
          talkTime: summary.talkTime,
          avgTalkTime: summary.avgTalkTime,
          inbound,
          outbound
        };
  
        // table agents
        const agents = Object.entries(agentStats).map(([userId, calls]) => ({ userId, calls }));
  
        setDashboardData({ dataWidget, barTotalCall, barCallTimes, calls, agents });
      } else {
        setDashboardData({
          dataWidget: crmWidgets,
          barTotalCall: { options: null, series: null },
          barCallTimes: { options: null, series: null },
          calls: null,
          agents: []
        });
      }
    }, [data, isSuccess, t]);
  
    return dashboardData;
  };
  
  export default useDashboardData;