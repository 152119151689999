import ReactApexChart from 'react-apexcharts';

const BasicLineCharts = ({ options, series }) => {

	if(!options || !series) return null

	return (
		<ReactApexChart
			dir="ltr"
			options={options}
			series={series}
			type="line"

			height="350"
			className="apex-charts"
		/>
	);
};

export default BasicLineCharts;
