import { del, get, post, put } from '../api_helper';
import * as url from '../url';


const getSurveyPaginate = (query) => get(`${url.survey}${query}`);
const createSurvey = (data) => post(`${url.survey}`, data);
const getSurveyQuestionTypes = async () => await get(`${url.survey}/getQuestionTypes`);
const deleteSurvey = async (data) => await del(`${url.survey}/${data.id}`);
const getSurveyById = async (id) => await get(`${url.survey}/${id}`);
const getSurveyPaginateByUserConnected = async (query) => await get(`${url.survey}/getAllSurveyByUsername${query}`);
const updateSurvey = async (data) =>  await put(`${url.survey}/${data.id}`, data.body);
const cloneSurvey = async (data) => await post(`${url.survey}/duplicate/${data.id}`, {});
const sendSMSSurvey = async (data) => await post(`${url.survey}/sendSMSSurvey`, data);
const sendEmailSurvey = async (data) => await post(`${url.survey}/sendEmailSurvey`, data);

export {
    getSurveyPaginate,
    createSurvey,
    getSurveyQuestionTypes,
    deleteSurvey,
    getSurveyById,
    getSurveyPaginateByUserConnected,
    updateSurvey,
    cloneSurvey,
    sendSMSSurvey,
    sendEmailSurvey
};