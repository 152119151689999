import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { SELECT_OPTION, SMS_SUCCESS } from "../../../../../constants/messages";
import { useMutation } from "react-query";
import useBanner from "../../../../../../hooks/useBanner";
import { sendSMSSurvey } from "../../../../../../helpers/catalogues/survey";
import useUser from "../../../../../../hooks/useUser";
import ButtonsLoader from "../../../../../Loader/ButtonsLoader";

const FormSurveySendBySMS = ({ customer, closeModal, surveySelected }) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.operation.surveyAnswer',
    });
    const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
    const banner = useBanner();
    const user = useUser()
    const [showSendSMS, setShowendSMS] = useState(false);
    const [phone, setPhone] = useState(null);
    const phonesOpt = useMemo(() => {
		if (customer?.id) {
			const arrayPhones = [];
			if (customer.phone1) {
				arrayPhones.push({ value: 'phone1', label: customer.phone1 });
			}
			if (customer.phone2) {
				arrayPhones.push({ value: 'phone2', label: customer.phone2 });
			}
			if (customer.phone3) {
				arrayPhones.push({ value: 'phone3', label: customer.phone3 });
			}
			if (customer.movil) {
				arrayPhones.push({ value: 'movil', label: customer.movil });
			}
			return arrayPhones;
		}
		return null;
	}, [
		customer?.id,
		customer.phone1,
		customer.phone2,
		customer.phone3,
		customer.movil,
	]);
    //send sms
	const { mutate: sendSms, isLoading: isSendingSms } = useMutation(
		sendSMSSurvey,
		{
			onSuccess: () => {
				banner.simpleSuccess(tMessage(SMS_SUCCESS));
				closeModal();
			},
			onError: (error) => {
				banner.simpleError(error);
			},
		}
	);

    const handleSend = () => {
        if(!surveySelected || !phone){
            banner.simpleWarning(t('validateSendSMS'))
        }else{
            const data = {
                "customer": customer.id,
                "survey": surveySelected.id,
                "user": user.usuario,
                "phone": phone.value
            }
            sendSms(data)
        }
    }

    return (
        <>
            <Row>
                <Col xs="12" lg="12">
                    <div className="mb-2">
                        <div className="form-check me-4">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="showSendSMS"
                                name="showSendSMS"
                                checked={showSendSMS}
                                onChange={(evt) =>
                                    setShowendSMS(
                                        evt.target.checked
                                    )
                                }
                            />
                            <Label
                                className="form-check-label"
                                htmlFor="showSendSMS"
                            >
                                {t('sendSms')}
                            </Label>
                        </div>
                    </div>
                </Col>
            </Row>
            {
                showSendSMS && 
                <Row>
                    <Col xs="8" lg="9">
                        <div className="mb-2">
                            <Label className="form-label mb-0" htmlFor="nota">
                                {t('number')}
                            </Label>
                            <Select
                                id="phone"
                                className="mb-0"
                                value={phone}
                                onChange={(value) =>
                                    setPhone(value)
                                }
                                options={phonesOpt}
                                placeholder={tMessage(SELECT_OPTION)}
                            />
                        </div>
                    </Col>
                    <Col xs="4" lg="3">
                        <div className="d-flex flex-column mb-2">
                            <Label className="form-label mb-0 opacity-0" htmlFor="nota">
                                {t('number')}
                            </Label>
                            {isSendingSms && <ButtonsLoader buttons={[{ text: t('send'), color: 'success', loader: true }]} />}
                            {!isSendingSms &&  <Button type="button" color="success" onClick={handleSend}>
                                                    <i className="ri ri-send-plane-fill align-bottom" /> {t('send')}
                                                </Button>
                            }                           
                        </div>      
                    </Col>
                </Row>
            }
        </>        
    )
}

export default FormSurveySendBySMS